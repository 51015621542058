import { createTheme, ThemeProvider } from '@mui/material/styles';

// Create a custom theme.
const theme = createTheme({
  palette: {
    primary: {
      main: '#e56581', // A nice pink shade from your example.
    },
    secondary: {
      main: '#006b69', // A deep teal color.
    },
  },
  typography: {
    fontFamily: [
      '"Bitter"',
      '"Roboto Flex"',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20, // Rounded buttons
        },
      },
    },
  },
});

export default theme;
