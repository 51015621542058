import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme'; // Ensure this path matches where your theme is defined
import ProtectedRoute from './utils/ProtectedRoute';
import LoginPage from './components/LoginPage';
import Cabinet from './components/Cabinet';

const App = () => (
  <ThemeProvider theme={theme}> {/* Wrap the entire Router with ThemeProvider */}
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/cabinet"
          element={
            <ProtectedRoute>
              <Cabinet />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<Navigate replace to="/cabinet" />} />
      </Routes>
    </Router>
  </ThemeProvider>
);

export default App;
