import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, Stepper, Step, StepLabel, Grid, Card, CardContent, TextField } from '@mui/material';
import Lottie from 'react-lottie';
import animationData from './character.json';
import axios from 'axios';
import StarIcon from '@mui/icons-material/Star'; // Ensure this is correctly imported
import { Divider } from '@mui/material';

const Cabinet = () => {
  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [activeStepDetails, setActiveStepDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fieldValues, setFieldValues] = useState({});

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  

  const renderActiveStepContent = () => {
    console.log(activeStepDetails);
    if (!activeStepDetails) {
      return <Typography>Loading step details...</Typography>;
    }

    switch(activeStepDetails.type) {
      case 'http://example.org/documentFilling':
        return renderDocumentFillingFields(activeStepDetails);
      // Handle other types as necessary
      default:
        return <Typography>Step details not available.</Typography>;
    }
  };

  const renderDocumentFillingFields = (stepDetails) => {
    // Determine if stepDetails.fields is an array; if not, create an array with stepDetails as its only item
    const fields = Array.isArray(stepDetails.fields) ? stepDetails.fields : [stepDetails];

    return (
      <Box>
        <Typography variant="h5" sx={{ mt: 2, mb: 2, marginTop: 6}}>Fill in the missing details</Typography>
        {fields.map((field, index) => (
          <Box key={index} sx={{ marginTop: 4 }}>
            <Typography>{field.description}</Typography>
            <TextField
              label={field.description}
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => updatePropertyForAlice(field.property, e.target.value)}
            />
          </Box>
        ))}
      </Box>
    );
  };



  const updatePropertyForAlice = async (propertyUri, newValue) => {
    const deleteQuery = `
      PREFIX ex: <http://example.org/>
      DELETE WHERE {
        ex:Alice <${propertyUri}> ?oldValue .
      };
    `;

    const insertQuery = `
      PREFIX ex: <http://example.org/>
      INSERT DATA {
        ex:Alice <${propertyUri}> "${newValue}" .
      };
    `;

    try {
      // Assume `axios.post` to your SPARQL update endpoint
      await axios.post('http://localhost:5001/api/update', { query: deleteQuery });
      await axios.post('http://localhost:5001/api/update', { query: insertQuery });
      console.log('Property updated successfully for Alice.');
    } catch (error) {
      console.error('Failed to update property for Alice:', error);
    }
  };

  useEffect(() => {
    // Assuming `steps` is an array of objects where each object has an `id` field.
    const currentStepUri = steps[activeStep]?.uri; // Adjust based on your actual data structure.

    console.log(currentStepUri )

    const fetchStepDetails = async () => {
      if (!currentStepUri) return; // Ensure there's a URI to query for.

      const query = `
        PREFIX ex: <http://example.org/>
        PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
        PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
        SELECT ?type ?field ?relatedObject ?property ?description WHERE {
          <${currentStepUri}> rdf:type ?type .
          OPTIONAL {
            <${currentStepUri}> ex:hasField ?field .
            OPTIONAL {
              ?field ex:hasRelatedObject ?relatedObject .
              OPTIONAL {
                ?field ex:hasPropertyOnRelatedObject ?property .
                OPTIONAL { ?property ex:description ?description . }
              }
            }
          }
        }
      `;

      try {
        const response = await axios.post('http://localhost:5001/api/query', {
          query: query,
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/sparql-results+json',
          },
        });

        const fetchedData = response.data.results.bindings.map(binding => ({
          type: binding.type.value,
          field: binding.field?.value,
          relatedObject: binding.relatedObject?.value,
          property: binding.property?.value,
          description: binding.description?.value,
        }));

        // Process fetched data to update UI
        console.log(fetchedData);
        setActiveStepDetails(fetchedData[0]);
        // Here, you would set state that dictates the UI based on fetched step details.
      } catch (error) {
        console.error('Failed to fetch step details:', error);
      }
    };

    fetchStepDetails();
  }, [activeStep, steps]);

  useEffect(() => {
  const fetchProcessSequence = async () => {
    setLoading(true);
    try {
      const response = await axios.post('http://localhost:5001/api/query', {
        query: `
          PREFIX ex: <http://example.org/>
          PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
          PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>

          SELECT ?nextSteps ?stepLabel (EXISTS { ?nextSteps rdf:type ex:event } AS ?isEvent) WHERE {
            ?startStep ex:hasStart ?initialStep .
            ?initialStep ex:hasNextStep* ?nextSteps .
            OPTIONAL { ?nextSteps ex:label ?stepLabel } .
          }
        `,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      // Adjust this part to handle steps and events properly
      const fetchedSteps = response.data.results.bindings.map(binding => ({
        uri: binding.nextSteps.value, // Capture the URI of each step
        label: binding.stepLabel?.value || 'Unnamed Step', // Provide a default label if none is available
        isEvent: binding.isEvent.value === "true", // Convert the string representation to a boolean
      }));

      setSteps(fetchedSteps);
    } catch (error) {
      console.error('Failed to fetch steps:', error);
    } finally {
      setLoading(false);
    }
  };

  fetchProcessSequence();
}, []);


  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4, width: '100%' }}>
      <Typography variant="h3" sx={{ mt: 4, textAlign: 'center' }}>Personal Cabinet</Typography>

      {/* Grid container to hold the cards */}
      <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
        {/* Left Card */}
        <Grid item sx={{ marginTop: 3}} xs={3 } sm={1.2}>
        <Card>
          <CardContent>
            <Typography variant="h6" sx={{textAlign: 'center'}}><Typography sx={{ fontSize:'4em', opacity: 0.7 }}>📣</Typography> Weekly tips & tricks </Typography>
            {/* Content for your personal data and document vault */}
          </CardContent>
        </Card>
        </Grid>

        {/* Center Card */}
        <Grid item xs={4} sm={1.5}>
          <Paper elevation={3} sx={{ padding: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Lottie options={defaultOptions} height={200} width={200} />
            <Typography variant="h5" component="h2" sx={{ mt: 2 }}>Welcome, Alice!</Typography>
          </Paper>
        </Grid>

        {/* Right Card */}
        <Grid item sx={{ marginTop: 3}} xs={3} sm={1.2}>
          <Card>
            <CardContent>
              <Typography variant="h6" sx={{textAlign: 'center'}}><Typography sx={{ fontSize:'4em', opacity: 0.7 }}>🔒</Typography> Your personal data vault</Typography>
              {/* Content for your personal data and document vault */}
            </CardContent>
          </Card>
        </Grid>
      </Grid>


      <Divider sx={{ width: '100%', my: 2, mt: 6 }} />
      <Typography variant="h4" sx={{ marginTop: 6, textAlign: 'center' }}>{loading ? 'Loading Bureaucratic Journey Progress...' : 'Personalized Journey Progress'}</Typography>
      {!loading && (
        <Stepper activeStep={activeStep} sx={{ width: '90%', maxWidth: 800, marginTop: 7,

        '.MuiStepIcon-root': { // Target the step icons
          '&.MuiStepIcon-active': { // Styles for active icon
            fontSize: '3rem', // Increase the icon size
          },
          '&.MuiStepIcon-completed': { // Styles for completed icons
            fontSize: '3rem', // Increase the icon size
          },
        },
        '.MuiStepLabel-label': { // Target the step labels
          fontSize: '1.25rem', // Increase the font size of labels
        },
      }} alternativeLabel>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel icon={step.isEvent ? <StarIcon sx={{ color: 'gray' }} /> : index + 1} sx={{ textAlign: 'center', '.MuiStepLabel-iconContainer': { justifyContent: 'center' } }}>
                {step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      )}

      {!loading && renderActiveStepContent()}
    </Box>
  );
};

export default Cabinet;
